/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useGetPaymentsQuery } from "api/apiSlice";
import { Icon, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import KW from "assets/images/icons/flags/KW.png";
// import AU from "assets/images/icons/flags/AU.png";

export default function UsersTable() {
  const { data: categories } = useGetPaymentsQuery();

  const categoryList = categories?.data?.data || [];

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Status = ({ badgeContent, color }) => (
    <MDBox ml={-1}>
      <MDBadge badgeContent={badgeContent} color={color} variant="gradient" size="lg" />
    </MDBox>
  );

  const rows = categoryList.map((option) => {
    const createdAtDate = new Date(option.created_at * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    return {
      date: <Job title={createdAtDate.toLocaleString("en-GB")} />,
      name: <Job title={option.title} />,
      email: <Job title={option.plan_for} description={option.before_price} />,
      country: <Company image={option.country === "KW" ? KW : KW} />,
      //   subcategories: <Status badgeContent={option.totalSubcategory || "0"} color="dark" />,

      paymentMethod: <Job title="Knet" />,
      active: (
        <MDButton variant="gradient" color={option.approved === 1 ? "success" : "error"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{option.approved === 1 ? "Paid" : "Unpaid"}</span>
          </div>
        </MDButton>
      ),
    };
  });

  return {
    columns: [
      { Header: "date", accessor: "date", align: "left" },
      { Header: "ads name", accessor: "name", align: "left" },
      { Header: "package", accessor: "email", align: "left" },
      { Header: "country", accessor: "country", align: "left" },
      { Header: "payment method", accessor: "paymentMethod", align: "left" },
      { Header: "payemnt status", accessor: "active", align: "center" },
    ],

    rows,
  };
}
