import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://aswaag.net/api/admin/",
    prepareHeaders: (headers) => {
      // Set common headers for all requests
      headers.set("x-app-key", "your_app_key_here");
      headers.set("app-lang", "1");
      headers.set("device-type", "1");
      headers.set(
        "x-auth-token",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQsIm5hbWUiOiJ0YW51IiwidXNlck5hbWUiOiJ0YW51IiwiZW1haWwiOiJ0YW51MTExMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJjb3VudHJ5IjoiS1ciLCJtb2JpbGUiOiIiLCJpc01vYmlsZVZlcmlmaWVkIjpmYWxzZSwiaWF0IjoxNzAxMDg5MjYxLCJleHAiOjE3MDI4MTcyNjF9.yw8dMW0133pk9HIaWq2jv5fMzk4x4rd0i5hX85kI6N0"
      );
      // Add any other common headers here
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => "category",
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "https://aswaag.net/api/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    createCategory: builder.mutation({
      query: (categoryData) => ({
        url: "https://aswaag.net/api/admin/category",
        method: "POST",
        body: categoryData,
      }),
    }),
    getUsers: builder.query({
      query: () => "user-list",
    }),
    getPayment: builder.query({
      query: () => "payment-list",
    }),
    getReviews: builder.query({
      query: () => "rating",
    }),
    getAds: builder.query({
      query: () => "user/ads",
    }),
    deleteAds: builder.mutation({
      query: (adId) => ({
        url: `https://aswaag.net/api/admin/delete/ads/${adId}`,
        method: "DELETE",
      }),
    }),
    statusUpdateAds: builder.mutation({
      query: (adsData) => ({
        url: "https://aswaag.net/api/admin/ads-approval",
        method: "POST",
        body: adsData,
      }),
    }),
    getPayments: builder.query({
      query: () => "user-ad-subscription",
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `https://aswaag.net/api/app/user/delete/category/${catId}`,
        method: "DELETE",
      }),
    }),
    statusUpdateCategory: builder.mutation({
      query: (categoryData) => ({
        url: "https://aswaag.net/api/admin/category-approval",
        method: "POST",
        body: categoryData,
      }),
    }),
    updateAds: builder.mutation({
      query: (adsData) => ({
        url: "https://aswaag.net/api/admin/edit-ads",
        method: "PUT",
        body: adsData,
      }),
    }),
    getStore: builder.query({
      query: () => "store-list",
    }),
    statusUpdateStore: builder.mutation({
      query: (storeData) => ({
        url: "https://aswaag.net/api/admin/store-approval",
        method: "POST",
        body: storeData,
      }),
    }),
    getSubCategory: builder.query({
      query: (subCatId) => `category?parentCategoryId=${subCatId}`,
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useLoginMutation,
  useCreateCategoryMutation,
  useGetUsersQuery,
  useGetPaymentQuery,
  useGetReviewsQuery,
  useGetAdsQuery,
  useDeleteAdsMutation,
  useStatusUpdateAdsMutation,
  useGetPaymentsQuery,
  useDeleteCategoryMutation,
  useUpdateAdsMutation,
  useStatusUpdateCategoryMutation,
  useGetStoreQuery,
  useStatusUpdateStoreMutation,
  useGetSubCategoryQuery,
} = api;

export default api;
