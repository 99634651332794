// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Categories from "layouts/catalog/categories";
import SubCategories from "layouts/catalog/categories/subCategories";
import Products from "layouts/catalog/products";
import List from "layouts/users/list";
import Roles from "layouts/users/roles";
import Payment from "layouts/payment";
import Reviews from "layouts/reviews";
import StoreCategory from "layouts/store/category";
import StoreList from "layouts/store/list";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Catalog",
    key: "catalog",
    icon: <Icon fontSize="small">sell</Icon>,
    collapse: [
      {
        name: "Ads",
        key: "ads",
        route: "/ads",
        component: <Products />,
      },
      {
        name: "Categories",
        key: "categories",
        route: "/categories",
        component: <Categories />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    collapse: [
      {
        name: "List",
        key: "list",
        route: "/list",
        component: <List />,
      },
      {
        name: "Roles",
        key: "roles",
        route: "/roles",
        component: <Roles />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Store",
    key: "store",
    icon: <Icon fontSize="small">group</Icon>,
    collapse: [
      {
        name: "Store List",
        key: "store-list",
        route: "/store-list",
        component: <StoreList />,
      },
      {
        name: "Store Category",
        key: "store-category",
        route: "/store-category",
        component: <StoreCategory />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Payment",
    key: "payment",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/payment",
    component: <Payment />,
  },
  {
    type: "collapse",
    name: "Reviews",
    key: "reviews",
    icon: <Icon fontSize="small">reviews</Icon>,
    route: "/reviews",
    component: <Reviews />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },

  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "sticky",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },

  {
    // type: "collapse",
    name: "Sub Categories",
    key: "sub-categories",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/category/:id",
    component: <SubCategories />,
  },
];

export default routes;
