/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Img from "assets/images/home-decor-1.jpg";
import { useGetStoreQuery, useDeleteAdsMutation, useStatusUpdateStoreMutation } from "api/apiSlice";
import MDButton from "components/MDButton";
import { CircularProgress, Icon } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

// import MDBadge from "components/MDBadge";

export default function ProductsTable() {
  const { data: products, refetch } = useGetStoreQuery();
  const productList = products?.data?.data || [];
  const [isLoading, setIsLoading] = useState(null);

  const [isUpdating, setIsUpdating] = useState(null);

  const [deleteAdsMutation] = useDeleteAdsMutation();

  const [statusUpdateAdsMutation] = useStatusUpdateStoreMutation();

  // const handleDelete = async (storeId) => {
  //   try {
  //     await deleteAdsMutation(storeId);
  //     toast.success("Ad deleted successfully");
  //     refetch(); // Refetch ads after deletion
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleDelete = async (storeId) => {
    try {
      // Set isLoading to the storeId when the deletion process starts
      setIsLoading(storeId);

      // Perform the deleteCategory mutation
      await deleteAdsMutation(storeId);

      // Show success toast
      toast.success("Ad deleted successfully");

      // Refetch categories after deletion
      refetch();
    } catch (error) {
      // Show error toast
      toast.error(error.message);
    } finally {
      // Set isLoading back to null when the deletion process finishes
      setIsLoading(null);
    }
  };

  // const handleUpdateStatus = async (storeId, approved) => {
  //   const approvedStatus = approved === 0 ? "approved" : "Rejected";

  //   const statusData = {
  //     approved: approvedStatus,
  //     storeId: storeId,
  //   };

  //   try {
  //     await statusUpdateAdsMutation(statusData);
  //     toast.success("Ad status updated successfully");
  //     refetch(); // Refetch ads after status update
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleUpdateStatus = async (storeId, isApproved) => {
    const approvedStatus = isApproved === 0 ? "ENABLE" : "DISABLE";

    const statusData = {
      action: approvedStatus,
      storeId: storeId,
    };

    try {
      // Set isUpdating to the catId when the status update process starts
      setIsUpdating(storeId);

      await statusUpdateAdsMutation(statusData);
      toast.success("Ad status updated successfully");
      refetch(); // Refetch ads after status update
    } catch (error) {
      toast.error(error);
    } finally {
      // Set isUpdating back to null when the status update process finishes
      setIsUpdating(null);
    }
  };

  const Author = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="lg" />
    </MDBox>
  );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const rows = (productList || []).map((option) => {
    const createdAtDate = new Date(option.createdAt * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    return {
      avatar: (
        <Author
          image={`https://aswagbuckets3.s3.me-south-1.amazonaws.com/${option.logoImage || Img}`}
        />
      ),
      name: <Job title={option.storeName} />,
      location: <Job title={option.address} />,
      phoneNumber: <Job title={option.mobile} />,
      email: <Job title={option.email} />,
      createdAt: <Job title={createdAtDate.toLocaleString("en-GB")} />,

      edit: (
        <MDButton variant="gradient" color="info">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ marginRight: "4px" }}>edit</Icon>
            <span>Edit</span>
          </div>
        </MDButton>
      ),
      active: (
        // <MDButton
        //   variant="gradient"
        //   color={option.approved === 1 ? "success" : "warning"}
        //   onClick={() => handleUpdateStatus(option.storeId, option.approved)}
        // >
        //   <div style={{ display: "flex", alignItems: "center" }}>
        //     <Icon style={{ marginRight: "4px" }}>power_settings_new</Icon>
        //     <span>{option.approved === 1 ? "Active" : "Deactive"}</span>
        //   </div>
        // </MDButton>

        <MDButton
          disabled={isUpdating === option.storeId} // Disable the button when its storeId is being updated
          variant="gradient"
          color={option.isApproved === 1 ? "success" : "warning"}
          onClick={() => handleUpdateStatus(option.storeId, option.isApproved)}
        >
          {isUpdating === option.storeId ? ( // Conditional rendering of loader or approved button text
            <CircularProgress size={24} color="inherit" /> // Render loader when its storeId is being updated
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon style={{ marginRight: "4px" }}>power_settings_new</Icon>
              <span>{option.isApproved === 1 ? "Active" : "Deactive"}</span>
            </div>
          )}
        </MDButton>
      ),
      delete: (
        // <MDButton variant="gradient" color="error" onClick={() => handleDelete(option.storeId)}>
        //   <div style={{ display: "flex", alignItems: "center" }}>
        //     <Icon style={{ marginRight: "4px" }}>delete</Icon>
        //     <span>Delete</span>
        //   </div>
        // </MDButton>

        <MDButton
          disabled={isLoading === option.storeId} // Disable the button when its storeId is being deleted
          variant="gradient"
          color="error"
          onClick={() => handleDelete(option.storeId)}
        >
          {isLoading === option.storeId ? ( // Conditional rendering of loader or delete button text
            <CircularProgress size={24} color="inherit" /> // Render loader when its storeId is being deleted
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon style={{ marginRight: "4px" }}>delete</Icon>
              <span>Delete</span>
            </div>
          )}
        </MDButton>
      ),
    };
  });

  return {
    columns: [
      { Header: "image", accessor: "avatar", width: "50px", align: "center" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "location", accessor: "location", align: "left" },
      { Header: "phone number", accessor: "phoneNumber", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "date", accessor: "createdAt", align: "left" },
      // { Header: "edit", accessor: "edit", align: "left" },
      { Header: "active", accessor: "active", align: "left" },
      // { Header: "delete", accessor: "delete", align: "left" },
    ],
    rows,
  };
}
