import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Icon } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useGetReviewsQuery } from "api/apiSlice";
import categoriesTableData from "layouts/reviews/data/reviewsTableData";
import MDInput from "components/MDInput";

function Reviews() {
  const { data: categories, isLoading, isError } = useGetReviewsQuery();
  const categoryList = categories?.data?.data || [];

  const { columns, rows } = categoriesTableData(); // Ensure this function returns columns and rows

  return (
    <DashboardLayout>
      {/* <MDBox>
        <Grid item xs={12}>
          <MDInput label="Search here..." size="large" />
        </Grid>
      </MDBox> */}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <CircularProgress size="3rem" color="info" />
                </div>
              )}
              <ToastContainer />
              {!isLoading && !isError && categoryList && (
                <MDBox pt={3}>
                  {rows && rows.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted
                      entriesPerPage
                      showTotalEntries
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Icon fontSize="large">mood_bad</Icon>
                      <span style={{ marginLeft: "10px" }}>No data available</span>
                    </div>
                  )}
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reviews;
