/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import {
  useGetSubCategoryQuery,
  useDeleteCategoryMutation,
  useStatusUpdateCategoryMutation,
} from "api/apiSlice";
import { CircularProgress, Icon, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import { ToastContainer, toast } from "react-toastify";

import Img from "assets/images/home-decor-1.jpg";

import "react-toastify/dist/ReactToastify.css";
import MDAvatar from "components/MDAvatar";
import { Link, useParams } from "react-router-dom";

export default function CategoriesTable() {
  const match = { params: useParams() };
  const subCatId = match.params.id;
  const { data: categories, refetch } = useGetSubCategoryQuery(subCatId);
  const [isLoading, setIsLoading] = useState(null);
  const [isUpdating, setIsUpdating] = useState(null);

  const categoryList = categories?.data?.data || [];

  const [deleteCategory, { isError, isSuccess }] = useDeleteCategoryMutation();

  const [statusUpdateCategoryMutation] = useStatusUpdateCategoryMutation();

  const handleDelete = async (categoryId) => {
    try {
      // Set isLoading to the categoryId when the deletion process starts
      setIsLoading(categoryId);

      // Perform the deleteCategory mutation
      await deleteCategory(categoryId);

      // Show success toast
      toast.success("Category deleted successfully");

      // Refetch categories after deletion
      refetch();
    } catch (error) {
      // Show error toast
      toast.error(error.message);
    } finally {
      // Set isLoading back to null when the deletion process finishes
      setIsLoading(null);
    }
  };

  const handleUpdateStatus = async (catId, approved) => {
    const approvedStatus = approved === 0 ? "approved" : "Rejected";

    const statusData = {
      action: approvedStatus,
      categoryId: catId,
    };

    try {
      // Set isUpdating to the catId when the status update process starts
      setIsUpdating(catId);

      await statusUpdateCategoryMutation(statusData);
      toast.success("Category status updated successfully");
      refetch(); // Refetch ads after status update
    } catch (error) {
      toast.error(error);
    } finally {
      // Set isUpdating back to null when the status update process finishes
      setIsUpdating(null);
    }
  };

  const Author = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="lg" />
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Status = ({ badgeContent, color }) => (
    <MDBox ml={-1}>
      <MDBadge badgeContent={badgeContent} color={color} variant="gradient" size="lg" />
    </MDBox>
  );

  const rows = categoryList.map((option) => {
    return {
      avatar: (
        <Author
          image={`https://aswagbuckets3.s3.me-south-1.amazonaws.com/${
            option.categoryAppImage || Img
          }`}
        />
      ),
      name: <Job title={option.categoryNameEn} />,
      subcategories: (
        <MDButton
          variant="gradient"
          color="secondary"
          // onClick={() => handleUpdateStatus(option.adId, option.active)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ marginRight: "4px" }}>category</Icon>
            <span>{option.totalSubcategory}</span>
          </div>
        </MDButton>
      ),
      //  <Status badgeContent={option.totalSubcategory || "0"} color="dark" />,

      active: (
        <MDButton
          disabled={isUpdating === option.categoryId} // Disable the button when its categoryId is being updated
          variant="gradient"
          color={option.active === 1 ? "success" : "warning"}
          onClick={() => handleUpdateStatus(option.categoryId, option.active)}
        >
          {isUpdating === option.categoryId ? ( // Conditional rendering of loader or active button text
            <CircularProgress size={24} color="inherit" /> // Render loader when its categoryId is being updated
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon style={{ marginRight: "4px" }}>power_settings_new</Icon>
              <span>{option.active === 1 ? "Active" : "Deactive"}</span>
            </div>
          )}
        </MDButton>
      ),

      edit: (
        <MDButton variant="gradient" color="info">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ marginRight: "4px" }}>edit</Icon>
            <span>Edit</span>
          </div>
        </MDButton>
      ),
      delete: (
        <MDButton
          disabled={isLoading === option.categoryId} // Disable the button when its categoryId is being deleted
          variant="gradient"
          color="error"
          onClick={() => handleDelete(option.categoryId)}
        >
          {isLoading === option.categoryId ? ( // Conditional rendering of loader or delete button text
            <CircularProgress size={24} color="inherit" /> // Render loader when its categoryId is being deleted
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon style={{ marginRight: "4px" }}>delete</Icon>
              <span>Delete</span>
            </div>
          )}
        </MDButton>
      ),
    };
  });

  return {
    columns: [
      { Header: "image", accessor: "avatar", width: "50px", align: "center" },
      { Header: "name", accessor: "name", align: "left" },
      //   { Header: "subcategories", accessor: "subcategories", align: "left" },
      { Header: "active", accessor: "active", align: "left" },
      // { Header: "edit", accessor: "edit", align: "left" },
      { Header: "delete", accessor: "delete", align: "left" },
    ],

    rows,
  };
}
