import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {
  CircularProgress,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useGetSubCategoryQuery, useCreateCategoryMutation } from "api/apiSlice";
import categoriesTableData from "layouts/catalog/categories/subCategories/data/subCategoriesTableData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useParams } from "react-router-dom";

function Categories() {
  const match = { params: useParams() };
  const subCatId = match.params.id;

  const { data: categories, isLoading, isError, refetch } = useGetSubCategoryQuery(subCatId);
  const categoryList = categories?.data?.data || [];
  const [openDialog, setOpenDialog] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  const handleAddCategory = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [categoryData, setCategoryData] = useState({
    categoryNameEn: "",
    categoryNameAr: "",
    icon: null,
    appImage: null,
    image: null,
    is_visible: isActive,
    // Add other fields for the category data
  });

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      const file = files[0]; // Assuming single file upload
      setCategoryData({
        ...categoryData,
        [name]: file, // Set only the file name in categoryData
      });
    } else {
      setCategoryData({
        ...categoryData,
        [name]: value,
      });
    }
  };

  const [createCategoryMutation, { data: responseData, error }] = useCreateCategoryMutation();

  useEffect(() => {
    if (error) {
      toast.error(error.data.errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (responseData) {
      setSuccessMessage("Category added successfully!");
      refetch();
      handleCloseDialog();
    }
  }, [responseData]);

  // useEffect(() => {
  //   if (error) {
  //     setErrorMessage("Failed to add category. Please try again.");
  //     toast.error(errorMessage);
  //   }
  // }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      const formData = new FormData();
      formData.append("categoryNameEn", categoryData.categoryNameEn);
      formData.append("categoryNameAr", categoryData.categoryNameAr);
      formData.append("icon", categoryData.icon);
      formData.append("appImage", categoryData.appImage);
      formData.append("image", categoryData.image);
      formData.append("is_visible", categoryData.is_visible);

      await createCategoryMutation(formData);
    } catch (error) {
      setErrorMessage(error.response.data.errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsAdding(false);
    }
  };

  const { columns, rows } = categoriesTableData(); // Ensure this function returns columns and rows

  return (
    <DashboardLayout>
      <MDBox>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            {/* <Grid item py={1}>
              <MDButton variant="gradient" color="info" onClick={handleAddCategory}>
                Add New Category
              </MDButton>
            </Grid> */}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <CircularProgress size="3rem" color="info" />
                </div>
              )}
              <ToastContainer />
              {!isLoading && !isError && categoryList && (
                <MDBox pt={3}>
                  {rows && rows.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted
                      entriesPerPage
                      showTotalEntries
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Icon fontSize="large">mood_bad</Icon>
                      <span style={{ marginLeft: "10px" }}>No data available</span>
                    </div>
                  )}
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <DialogTitle>Add New Category</DialogTitle>
          <DialogContent>
            {/* Add your form fields here */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="button">Category Name English</MDTypography>
                <MDInput
                  required
                  name="categoryNameEn"
                  value={categoryData.categoryNameEn}
                  onChange={handleInputChange}
                  // value={categoryNameEn}
                  // onChange={(e) => setCategoryNameEn(e.target.value)}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="button">Category Name Arabic</MDTypography>
                <MDInput
                  required
                  name="categoryNameAr"
                  value={categoryData.categoryNameAr}
                  onChange={handleInputChange}
                  // value={categoryNameAr}
                  // onChange={(e) => setCategoryNameAr(e.target.value)}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">Icon</MDTypography>
                <MDInput required name="icon" onChange={handleInputChange} type="file" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">App Image</MDTypography>
                <MDInput
                  required
                  name="appImage"
                  onChange={handleInputChange}
                  type="file"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">Image</MDTypography>
                <MDInput required name="image" onChange={handleInputChange} type="file" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                  <MDTypography variant="button"> isActive</MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton type="submit" variant="gradient" color="success" disabled={isAdding}>
              {isAdding ? <CircularProgress color="info" size="1rem" /> : "Save"}
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  );
}

export default Categories;
