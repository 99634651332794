import { useEffect, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useLoginMutation } from "api/apiSlice";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Basic() {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [loginMutation, { data, error, isLoading }] = useLoginMutation();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Call the loginMutation function with credentials
      const response = await loginMutation({ userName, password });
      const userInfo = response && response.data;
      if (userInfo && userInfo.status === "success") {
        toast.success("Login Success");
        localStorage.setItem("userData", JSON.stringify(response.data));
        navigate("/dashboard");
      } else {
        toast.error("Incorrect Username or Password");
      }
    } catch (err) {
      // Handle error
      toast.error("Login Failed", err);
      // console.error("Login failed:", err);
    }
  };

  useEffect(() => {
    if (data && data.status === "success") {
      toast.success("Login Success");
    } else if (data && data.status === "error") {
      toast.error("Login Failed");
    }
  }, [data]);

  useEffect(() => {
    toast.error(error);
  }, [error]);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <BasicLayout image={bgImage}>
      {isLoading && (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            position: "fixed",
            top: 0,
            zIndex: 100,
            left: 0,
            backgroundColor: "#00000026",
            width: "100vw",
            height: "100vh",
          }}
        >
          <CircularProgress size="3rem" color="info" />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>

            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleLogin}>
            <MDBox mb={2}>
              <MDInput
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                label="Username"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>

            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
