/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */

import { v4 as uuidv4 } from "uuid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Icon } from "@mui/material";

import { useGetReviewsQuery } from "api/apiSlice";

export default function ReviewsData() {
  const { data: categories } = useGetReviewsQuery();
  const categoryList = categories?.data?.data || [];

  const Author = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="lg" />
    </MDBox>
  );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const StarRating = ({ stars }) => {
    const fullStars = Math.floor(stars);
    const halfStars = Math.ceil(stars - fullStars);
    const emptyStars = 5 - fullStars - halfStars;

    const renderStars = (count, type) => {
      const starType = type === "full" ? "star" : type === "half" ? "star_half" : "star_border";
      return Array(count)
        .fill()
        .map(() => (
          <Icon key={uuidv4()} color="warning">
            {starType}
          </Icon>
        ));
    };

    return (
      <MDBox>
        {renderStars(fullStars, "full")}
        {renderStars(halfStars, "half")}
        {renderStars(emptyStars, "empty")}
      </MDBox>
    );
  };

  const rows = (categoryList || []).map((option) => ({
    // avatar: (
    //   <Author
    //     key={option.id || uuidv4()}
    //     image={`${config.IMAGE_URL}/media/avatars/${
    //       option.avatar ||
    //       (option.client_gender === 0
    //         ? "Avatars-02.png"
    //         : option.client_gender === 1
    //         ? "Avatars-01.png"
    //         : "Avatars-01.png")
    //     }`}
    //   />
    // ),
    name: <Job key={option.id || uuidv4()} title={option.name} />,
    phone: <Job key={option.id || uuidv4()} title={option.first_name || "None"} />,
    date: <Job key={option.id || uuidv4()} title={option.Time} />,
    stars: <StarRating key={option.id || uuidv4()} stars={option.rating} />,
    comment: <Job key={option.id || uuidv4()} title={option.comment} />,
  }));

  return {
    columns: [
      //   { Header: "avatar", accessor: "avatar", width: "10px", align: "left" },
      { Header: "ads", accessor: "name", align: "left" },
      { Header: "user", accessor: "phone", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "rating", accessor: "stars", align: "left" },
      { Header: "comment", accessor: "comment", align: "left" },
    ],
    rows,
  };
}
