/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import MDAvatar from "components/MDAvatar";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [usersDropDown, setUsersDropDown] = useState(false);
  const [catalogDropDown, setCatalogDropDown] = useState(false);
  const [storeDropDown, setStoreDropDown] = useState(false);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      if (href) {
        returnValue = (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </Link>
        );
      } else if (name === "Catalog") {
        returnValue = (
          <div key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              style={{
                backgroundColor: usersDropDown === true ? "rgba(206, 212, 218, 0.2)" : undefined,
              }}
              onClick={() => {
                if (usersDropDown === false) {
                  setUsersDropDown(true);
                  setCatalogDropDown(false);
                  setStoreDropDown(false);
                } else {
                  setUsersDropDown(false);
                  setCatalogDropDown(false);
                  setStoreDropDown(false);
                }
              }}
            />
            <div
              style={{
                height: usersDropDown === false ? "0px" : "100px",
                overflow: "hidden",
                transition: "height 0.5s",
              }}
            >
              {" "}
              <NavLink to="/ads">
                <SidenavCollapse name="Ads" icon="double_arrow" active={collapseName === "ads"} />
              </NavLink>
              <NavLink to="/categories">
                <SidenavCollapse
                  name="Categories"
                  icon="double_arrow"
                  active={collapseName === "categories"}
                />
              </NavLink>
            </div>
          </div>
        );
      } else if (name === "Users") {
        returnValue = (
          <div key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              style={{
                backgroundColor: catalogDropDown === true ? "rgba(206, 212, 218, 0.2)" : undefined,
              }}
              onClick={() => {
                if (catalogDropDown === false) {
                  setCatalogDropDown(true);
                  setUsersDropDown(false);
                  setStoreDropDown(false);
                } else {
                  setCatalogDropDown(false);
                  setUsersDropDown(false);
                  setStoreDropDown(false);
                }
              }}
            />
            <div
              style={{
                height: catalogDropDown === false ? "0px" : "100px",
                overflow: "hidden",
                transition: "height 0.5s",
              }}
            >
              <NavLink to="/list">
                <SidenavCollapse name="List" icon="double_arrow" active={collapseName === "list"} />
              </NavLink>
              <NavLink to="/roles">
                <SidenavCollapse
                  name="Roles"
                  icon="double_arrow"
                  active={collapseName === "roles"}
                />
              </NavLink>
            </div>
          </div>
        );
      } else if (name === "Store") {
        returnValue = (
          <div key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              style={{
                backgroundColor: storeDropDown === true ? "rgba(206, 212, 218, 0.2)" : undefined,
              }}
              onClick={() => {
                if (storeDropDown === false) {
                  setStoreDropDown(true);
                  setCatalogDropDown(false);
                  setUsersDropDown(false);
                } else {
                  setCatalogDropDown(false);
                  setUsersDropDown(false);
                  setStoreDropDown(false);
                }
              }}
            />
            <div
              style={{
                height: storeDropDown === false ? "0px" : "100px",
                overflow: "hidden",
                transition: "height 0.5s",
              }}
            >
              <NavLink to="/store-list">
                <SidenavCollapse
                  name="Store List"
                  icon="double_arrow"
                  active={collapseName === "store-list"}
                />
              </NavLink>
              <NavLink to="/store-category">
                <SidenavCollapse
                  name="Store Category"
                  icon="double_arrow"
                  active={collapseName === "store-category"}
                />
              </NavLink>
            </div>
          </div>
        );
      } else {
        returnValue = (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      }
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDAvatar name="img" src={brand} />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
