/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useGetUsersQuery } from "api/apiSlice";
import { Icon, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import KW from "assets/images/icons/flags/KW.png";
// import AU from "assets/images/icons/flags/AU.png";

export default function UsersTable() {
  const { data: categories } = useGetUsersQuery();

  const categoryList = categories?.data?.data || [];

  const Author = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="sm" />
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Status = ({ badgeContent, color }) => (
    <MDBox ml={-1}>
      <MDBadge badgeContent={badgeContent} color={color} variant="gradient" size="lg" />
    </MDBox>
  );

  const rows = categoryList.map((option) => {
    const createdAtDate = new Date(option.createdAt * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    return {
      date: <Job title={createdAtDate.toLocaleString("en-GB")} />,
      name: <Job title={option.firstName || "Guest"} />,
      email: <Job title={option.email || "Guest"} />,
      country: <Author image={option.country === "KW" ? KW : KW} />,
      //   subcategories: <Status badgeContent={option.totalSubcategory || "0"} color="dark" />,

      verifiedPhone: (
        // <Link to={`/edit-table?r=${restId}&id=${Data.id}`}>
        <Status
          badgeContent={option.isMobileVerified === 0 ? "Unactive" : "Active"}
          color={option.isMobileVerified === 0 ? "secondary" : "success"}
        />
        // </Link>
      ),

      edit: (
        // <Link to={`/edit-table?r=${restId}&id=${Data.id}`}>
        <MDButton variant="text" color="dark">
          <Icon>edit</Icon>
        </MDButton>
        // </Link>
      ),
      delete: (
        <MDButton
          variant="text"
          color="error"
          // onClick={() =>
          //   dispatch(restaurantTableDelete(Data.id)).then(() =>
          //     dispatch(getRestaurantTableList(restId, currentpage))
          //   )
          // }
        >
          <Icon>delete</Icon>
        </MDButton>
      ),
    };
  });

  return {
    columns: [
      { Header: "date", accessor: "date", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "country", accessor: "country", align: "left" },
      { Header: "verified phone", accessor: "verifiedPhone", align: "center" },
      // { Header: "edit", accessor: "edit", align: "left" },
      // { Header: "delete", accessor: "delete", align: "left" },
    ],

    rows,
  };
}
