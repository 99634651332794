/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import PieChart from "examples/Charts/PieChart";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="edit_note"
                title="Unactivated Ads"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="assignment_turned_in"
                title="Activated Ads"
                count="2300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person"
                title="Users"
                count="3400"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="language"
                title="Installed Countries"
                count="91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox mb={3}>
                <VerticalBarChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Ads Status"
                  description="Ads related to date average"
                  chart={{
                    labels: [
                      "30/11/2023",
                      "01/12/2023",
                      "02/12/2023",
                      "03/12/2023",
                      "04/12/2023",
                      "05/12/2023",
                    ],
                    datasets: [
                      {
                        label: "Ads by age",
                        color: "info",
                        data: [15, 20, 12, 60, 20, 15],
                      },
                    ],
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={3}>
                <VerticalBarChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title="User Status"
                  description="Users related to date average"
                  chart={{
                    labels: [
                      "30/11/2023",
                      "01/12/2023",
                      "02/12/2023",
                      "03/12/2023",
                      "04/12/2023",
                      "05/12/2023",
                    ],
                    datasets: [
                      {
                        label: "Users by age",
                        color: "info",
                        data: [30, 50, 72, 160, 40, 105],
                      },
                    ],
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Projects />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox pt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <PieChart
                icon={{ color: "info", component: "leaderboard" }}
                title="2023 Q4 Average by Ads/Country"
                description="Most Active Country"
                chart={{
                  labels: ["Kuwait", "Behrain", "Saudi Arabia", "UAE"],
                  datasets: {
                    label: "Ads",
                    backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
                    data: [70, 20, 12, 15],
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PieChart
                icon={{ color: "info", component: "leaderboard" }}
                title="2023 Q4 Average by Users/Country"
                description="Most Active Country"
                chart={{
                  labels: ["Kuwait", "Behrain", "Saudi Arabia", "UAE"],
                  datasets: {
                    label: "Users",
                    backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
                    data: [70, 20, 12, 15],
                  },
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
