import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {
  CircularProgress,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useGetUsersQuery, useCreateCategoryMutation } from "api/apiSlice";
import categoriesTableData from "layouts/users/list/data/listTableData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function Users() {
  const { data: categories, isLoading, isError } = useGetUsersQuery();
  const categoryList = categories?.data?.data || [];
  const [openDialog, setOpenDialog] = useState(false);

  // // State for form inputs
  // const [categoryNameEn, setCategoryNameEn] = useState("");
  // const [categoryNameAr, setCategoryNameAr] = useState("");
  // const [iconFile, setIconFile] = useState(null);
  // const [appImageFile, setAppImageFile] = useState(null);
  // const [imageFile, setImageFile] = useState(null);

  // File input change handlers
  // const handleIconChange = (e) => {
  //   setIconFile(e.target.files[0]);
  // };

  // const handleAppImageChange = (e) => {
  //   setAppImageFile(e.target.files[0]);
  // };

  // const handleImageChange = (e) => {
  //   setImageFile(e.target.files[0]);
  // };

  // const handleSubmit = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("categoryNameEn", categoryNameEn);
  //     formData.append("categoryNameAr", categoryNameAr);
  //     formData.append("icon", iconFile);
  //     formData.append("appImage", appImageFile);
  //     formData.append("image", imageFile);

  //     await createCategory(formData);

  //     // Close the dialog after successful submission
  //     handleCloseDialog();
  //     // Optionally, you can refetch data to update the category list
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleAddCategory = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [categoryData, setCategoryData] = useState({
    categoryNameEn: "",
    categoryNameAr: "",
    icon: null,
    appImage: null,
    image: null,
    // Add other fields for the category data
  });
  console.log(categoryData);

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    // For file inputs, store the file object in state
    if (files) {
      setCategoryData({
        ...categoryData,
        [name]: files[0], // Assuming single file upload, adjust as needed
      });
    } else {
      setCategoryData({
        ...categoryData,
        [name]: value,
      });
    }
  };

  // const { mutate: createCategory } = useCreateCategoryMutation();

  const [createCategoryMutation, { data }] = useCreateCategoryMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting:", categoryData); // Check if categoryData has the expected values
      await createCategoryMutation(categoryData);
      console.log(categoryData);
      handleCloseDialog();
    } catch (error) {
      toast.error(error);
    }
  };

  const { columns, rows } = categoriesTableData(); // Ensure this function returns columns and rows

  return (
    <DashboardLayout>
      <MDBox>
        {/* <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item py={1}>
              <MDButton variant="gradient" color="info" onClick={handleAddCategory}>
                Add New Category
              </MDButton>
            </Grid>
           
          </Grid>
        </Grid> */}
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <CircularProgress size="3rem" color="info" />
                </div>
              )}
              <ToastContainer />
              {!isLoading && !isError && categoryList && (
                <MDBox pt={3}>
                  {rows && rows.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted
                      entriesPerPage
                      showTotalEntries
                      noEndBorder
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Icon fontSize="large">mood_bad</Icon>
                      <span style={{ marginLeft: "10px" }}>No data available</span>
                    </div>
                  )}
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <DialogTitle>Add New Category</DialogTitle>
          <DialogContent>
            {/* Add your form fields here */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="button">Category Name English</MDTypography>
                <MDInput
                  name="categoryNameEn"
                  value={categoryData.categoryNameEn}
                  onChange={handleInputChange}
                  // value={categoryNameEn}
                  // onChange={(e) => setCategoryNameEn(e.target.value)}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="button">Category Name Arabic</MDTypography>
                <MDInput
                  name="categoryNameAr"
                  value={categoryData.categoryNameAr}
                  onChange={handleInputChange}
                  // value={categoryNameAr}
                  // onChange={(e) => setCategoryNameAr(e.target.value)}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">Icon</MDTypography>
                <MDInput name="icon" onChange={handleInputChange} type="file" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">App Image</MDTypography>
                <MDInput name="appImage" onChange={handleInputChange} type="file" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="button">Image</MDTypography>
                <MDInput name="image" onChange={handleInputChange} type="file" fullWidth />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox />
                <MDTypography variant="button"> isActive</MDTypography>
              </MDBox>
            </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton type="submit" variant="gradient" color="success">
              Save
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  );
}

export default Users;
