import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import api from "./api/apiSlice";

const initialUserData = localStorage.getItem("userData");
const initialState = initialUserData ? { user: JSON.parse(initialUserData) } : {};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  // Other reducers if any
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  preloadedState: initialState, // Include preloadedState here
});

setupListeners(store.dispatch);

export default store;
